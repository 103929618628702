import Breadcrumbs from "../breadcrumbs";

export default function CbBanner4({ block }) {
  return (
    <>
      <div className="container pt-30 pt-md-40">
        <div className="frame frame--justify-sm-center">
          <div className="bit-xxs-12 bit-sm-11 bit-md-10 bit-lg-9">
            <div className="pb-20">
              <Breadcrumbs html="breadcrumbs here" />
            </div>
            {block.title && (
              <div
                className="cb--banner_4__title pb-20"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="text--weight-normal pb-20"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
            {block.usps &&
              block.usps.length &&
              block.usps.map((usp) => {
                return (
                  usp.text && (
                    <p className="text--weight-normal pb-5" key={usp.text}>
                      <i className="fas fa-plus-circle text--white mr-10" />
                      <span>{usp.text}</span>
                    </p>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
