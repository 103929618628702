import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import classNames from "../../lib/classNames";

export default function CbContact({block}) {
    return (
        <>
            <div className="container">
                <div className="frame">
                    <div className="bit-xxs-12 pt-0 pb-0">
                        <div
                            className={classNames(
                                'cb--contact__wrapper',
                                'cb--contact__wrapper--' + block.bg_color,
                            )}>
                            {block.image && (
                                <figure className="cb--contact__image">
                                    <ResponsiveImage image={block.image}/>
                                </figure>
                            )}
                            {block.title && (
                                <div
                                    className="cb--contact__title pb-5"
                                    dangerouslySetInnerHTML={{__html: block.title}}
                                />
                            )}
                            {block.content && (
                                <div
                                    className="cb--contact__text pb-10 pb-md-20"
                                    dangerouslySetInnerHTML={{__html: block.content}}
                                />
                            )}

                            <div className="flex flex--align-flex-start flex--wrap">
                                {block.button_phone && (
                                    <Button
                                        button={block.button_phone}
                                        classes="button cb--contact__button--phone mr-15 mb-15 mb-md-0 mr-md-30"
                                    />
                                )}
                                {block.button_email && (
                                    <Button
                                        button={block.button_email}
                                        classes="button cb--contact__button--email"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
