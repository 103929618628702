import ResponsiveImage from "../modules/responsive-image";
import Fancybox from "../fancybox";

export default function CbVideo2({ block }) {
  return (
    <>
      <figure className="cb--video_2__placeholder">
        {block.image && (
          <ResponsiveImage
            image={block.image}
            classes="cb--video_2__placeholder__img"
            layout="fill"
          />
        )}
      </figure>
      {block.youtube_id && (
        <Fancybox options={{ infinite: false }}>
          <button
            className="cb--video_2__play-button"
            data-fancybox="video"
            data-src={`https://www.youtube.com/watch?v=${block.youtube_id}`}
          >
            <i className="fas fa-play" />
          </button>
        </Fancybox>
      )}
    </>
  );
}
