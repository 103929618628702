import {useState} from "react";
import classNames from "../../lib/classNames";
import Faq from "../faq";
import Accordion from "../accordion";

export default function CbFaq_2({block}) {
    const [categoryId, setCategoryId] = useState(false)

    const subCategories = block.categories.flatMap(category => category.children);

    function Button({title, onClick, isActive}) {
        return <span
            className={classNames(
                "tw-block tw-border-2 tw-border-solid tw-border-green tw-uppercase tw-font-bold tw-text-[14px] tw-px-7 tw-py-4 tw-rounded-full hover:tw-cursor-pointer tw-transition",
                isActive ? "tw-text-white tw-bg-green" : "tw-text-green hover:tw-text-white hover:tw-bg-green"
            )}
            dangerouslySetInnerHTML={{__html: title}}
            onClick={onClick}
        ></span>
    }

    function Category({title, questions, isLast}) {
        return <div className="container">
            <div className="tw-border-b tw-border-solid tw-border-green tw-mb-16 tw-pb-16 lg:tw-pb-28 lg:tw-mb-28">
                <h2 className="tw-text-[32px] lg:tw-text-[40px] tw-text-green tw-text-center tw-pb-12"
                    dangerouslySetInnerHTML={{__html: title}}></h2>
                {questions.length && questions.map((question) => {
                    return (<div className="tw-max-w-[1000px] tw-mx-auto tw-pb-6 tw-px-6 lg:px-0" key={question.title}>
                        <Accordion
                            title={question.title}
                            content={question.content}
                            color={block.settings && block.settings.font_color ? block.settings.font_color : null}
                        />
                    </div>);
                })}
            </div>
        </div>
    }

    return <>
        <div className="container">
            <div className="tw-flex tw-flex-wrap tw-gap-4 lg:tw-gap-8 tw-pb-24 tw-max-w-[1000px] tw-mx-auto">
                <Button title={block.translations.all} onClick={() => setCategoryId(false)} isActive={categoryId === false}/>
                {block.categories && block.categories.length && block.categories.map(category => {
                    return <Button
                        key={category.term_id}
                        title={category.name}
                        onClick={() => setCategoryId(category.term_id)}
                        isActive={categoryId === category.term_id}
                    />
                })}
            </div>
        </div>

        <div>
            {subCategories && subCategories.length && subCategories.map((subCategory, index) => {
                return (categoryId === false || categoryId === subCategory.parent) &&
                    <Category
                        title={subCategory.name}
                        parent_id={subCategory.parent}
                        questions={subCategory.questions}
                    />
            })}
        </div>
    </>;
}
