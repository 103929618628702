export default function CbEmbedCode({ block }) {
  return (
    <div className="container">
      <div
        className="flex flex--justify-center"
        dangerouslySetInnerHTML={{ __html: block.embed_code }}
      />
    </div>
  );
}
