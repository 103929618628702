import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import ImageWithLogo from "../image-with-logo";
import classNames from "../../lib/classNames";

export default function cbImageAndText2({block}) {
    return (
        <>
            <div className="container">
                <div className={classNames(
                    "tw-flex tw-flex-wrap tw-items-center tw-justify-center -tw-mx-6",
                    block.align_image_right ? 'lg:tw-flex-row-reverse' : '',
                )}>
                    <div className="tw-w-full md:tw-w-2/3 lg:tw-w-1/2 xl:tw-w-5/12 tw-px-6">
                        <figure>
                            {block.image && <ResponsiveImage image={block.image}/>}
                        </figure>
                    </div>
                    <div
                        className={classNames('tw-w-full md:tw-w-2/3 lg:tw-w-1/2 xl:tw-w-5/12 tw-px-6', block.text_align === 'left' ? 'tw-text-left' : 'tw-text-center')}>
                        {block.title && (
                            <div
                                className="[&>*]:tw-text-[32px] [&>*]:lg:tw-text-[48px] tw-pb-3"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                        {block.text && (
                            <div
                                className="tw-pb-6"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}
                        {block.button && <Button button={block.button} classes="button"/>}
                    </div>
                </div>
            </div>
        </>
    );
}
