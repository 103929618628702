export default function Review({title, text, rating, name, date}) {
    return (
        <div className="review p-30 pt-sm-50 pb-sm-50">
            <div className="pb-10">
                <span className="review__rating">{rating}</span>
                <p className="review__title">{title}</p>
                <div className="review__text" dangerouslySetInnerHTML={{__html: text}}/>
            </div>
            <div className="flex flex--column flex--align-center">
                <p className="p-0"><strong>{name}</strong></p>
                <p className="p-0">{date}</p>
            </div>
        </div>
    )
}