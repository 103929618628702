import ResponsiveImage from "./modules/responsive-image";

export default function TwoImagesAndLogo({ images, isGreen = false }) {
  return (
    <div
      className={`two-images-and-logo ${
        isGreen ? "two-images-and-logo--green" : ""
      }`}
    >
      {images.length &&
        images.map((img) => {
          return (
            <figure className="two-images-and-logo__image" key={img.src}>
              <ResponsiveImage image={img} />
            </figure>
          );
        })}
    </div>
  );
}
