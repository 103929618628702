import ResponsiveImage from "../modules/responsive-image";

export default function CbPricing({block}) {
    return (
        <>
            <div className="container">
                <div className="tw-text-center tw-pb-16">
                    {block.title && (<div
                        className="tw-pb-5"
                        dangerouslySetInnerHTML={{__html: block.title}}
                    />)}
                    {block.text && (<div
                        className="lg:tw-w-1/2 tw-mx-auto"
                        dangerouslySetInnerHTML={{__html: block.text}}
                    />)}
                </div>

                <div className="tw-flex tw-flex-wrap md:-tw-mx-8 tw-gap-y-16">
                    {block.cards && block.cards.length && block.cards.map((card) => {
                        return <>
                            <div className="tw-w-full md:tw-w-1/2 md:tw-px-8" key={card.title}>
                                <div className="tw-bg-white tw-text-black tw-rounded-3xl tw-px-20 tw-py-16">
                                    {card.title && <div className="[&>*]:!tw-text-green [&>*]:!tw-font-light tw-text-[40px] tw-pb-8"
                                                        dangerouslySetInnerHTML={{__html: card.title}}></div>}
                                    {card.usps && card.usps.length && card.usps.map((usp) => {
                                        return (<div className="flex" key={usp.text}>
                                            <i className="fas fa-check-circle mt-5 mr-10 !tw-text-green"/>
                                            <p className="pb-10">{usp.text}</p>
                                        </div>);
                                    })}
                                    <div className="tw-flex tw-gap-x-4 tw-items-baseline tw-pt-8">
                                        {card.price && <p className="tw-text-[40px] tw-font-bold">{card.price}</p>}
                                        {card.text_after_price && <p className="">{card.text_after_price}</p>}
                                    </div>
                                </div>
                            </div>
                        </>;
                    })}
                </div>
            </div>
        </>
    );
}
