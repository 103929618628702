import ResponsiveImage from "../modules/responsive-image";
import Breadcrumbs from "../breadcrumbs";
import Button from "../button";
import EnergyZeroAddressChecker from "../energy-zero/address-checker";

export default function CbEnergyZeroForm({block}) {
    return (
        <>
            <div className="container">
                <div className="tw-flex tw-flex-wrap tw-justify-center">
                    <div className="tw-w-full xl:tw-w-9/12 tw-text-center">
                        {block.text_above_title &&
                            <p className="tw-font-bold tw-text-black tw-text-[20px] lg:tw-text-[35px]">{block.text_above_title}</p>}
                        {block.title && (<div
                            className="tw-pb-5"
                            dangerouslySetInnerHTML={{__html: block.title}}
                        />)}
                        {block.text && (<div
                            className=" tw-pb-4"
                            dangerouslySetInnerHTML={{__html: block.text}}
                        />)}
                    </div>

                    <div className="tw-w-full lg:tw-w-1/2 tw-pt-10">
                        <div
                            className="tw-bg-white/90 tw-text-black tw-rounded-3xl tw-py-8 tw-px-12 lg:tw-py-10 lg:tw-px-20 tw-shadow-xl">
                            {block.box.title && (<p className="tw-text-green tw-font-bold tw-text-[34px]">
                                {block.box.title}
                            </p>)}
                            {block.rating.url && (
                                <a className="cb--energy_zero_form__rating flex pb-20" href={block.rating.url} target="_blank">
                                    {block.rating.stars && (<div
                                        className="pr-10"
                                        dangerouslySetInnerHTML={{__html: block.rating.stars}}
                                    />)}
                                    {block.rating.percentage && block.rating.text && (<div>
                                        <strong>{block.rating.percentage}%</strong>{" "}
                                        {block.rating.text}
                                    </div>)}
                                </a>)}
                            <div>
                                <EnergyZeroAddressChecker/>
                            </div>
                            <div>
                                {block.box.text_under_form &&
                                    <p className="tw-font-bold tw-text-[14px] tw-text-green tw-uppercase">{block.box.text_under_form}</p>}
                                {block.box.usps && block.box.usps.length && block.box.usps.map((usp) => {
                                    return (<div className="flex" key={usp.text}>
                                        <i className="fas fa-plus-circle mt-5 mr-10 !tw-text-green"/>
                                        <p className="pb-10">{usp.text}</p>
                                    </div>);
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
