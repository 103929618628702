import Breadcrumbs from "../breadcrumbs";

export default function CbBanner3({ block }) {
  const arrowButtonClickHandler = function (e) {
    e.preventDefault();
    let d = document.getElementById(block.arrow_href);
    window.scroll({ top: d.offsetTop, behavior: "smooth" });
  };

  return (
    <>
      <div className="container pt-20 pt-md-30">
        <div className="frame frame--justify-sm-center">
          <div className="bit-xxs-12 bit-sm-11 bit-md-10 bit-lg-9">
            <div className="pb-20">
              <Breadcrumbs html="breadcrumbs here" />
            </div>
            {block.title && (
              <div
                className="cb--banner_3__title pb-20"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="text--weight-normal pb-20"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
            {block.show_arrow && (
              <button
                onClick={arrowButtonClickHandler}
                className="cb--banner_3__arrow mt-20"
              >
                <i className="fas fa-arrow-down" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
