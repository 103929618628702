import {useEffect, useState} from "react"

export default function EnergyZeroAddressChecker() {
    const [error, setError] = useState(false)
    const [suggestedStreetName, setSuggestedStreetName] = useState('')

    const [areaCode, setAreaCode] = useState('')
    const [number, setNumber] = useState('')
    const [addition, setAddition] = useState('')

    const API_URL = process.env.NEXT_PUBLIC_ENERGY_ZERO_API_URL
    const REDIRECT_URL = process.env.NEXT_PUBLIC_ENERGY_ZERO_REDIRECT_URL

    useEffect(() => {
        if (areaCode.length < 6 || number.length < 1) {
            setSuggestedStreetName('')
            return
        }

        fetch(API_URL + 'metering-point/location/check', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                area_code: areaCode,
                house_number: number
            })
        })
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    return res.json()
                } else {
                    return false
                }
            })
            .then((data) => {
                if (data) {
                    setError(false)
                    setSuggestedStreetName(data.street_name + ' ' + data.house_number + ', ' + data.city)
                } else {
                    setError(true)
                    setSuggestedStreetName('')
                }
            })
    }, [areaCode, number, addition])

    function maybeSetError() {
        if (areaCode.length >= 6 && number.length > 0) {
            setError(true)
        } else {
            setError(false)
        }
    }

    function handleInputAreaCodeChange(e) {
        const regex = new RegExp('^\\d{4}\\s?\\w{2}$') // only allow postcodes like 1234AB
        const value = e.target.value.replace(' ', '')

        if (regex.test(value)) {
            setError(false)
        } else {
            maybeSetError()
        }

        setAreaCode(value)
    }

    function handleInputNumberChange(e) {
        const regex = new RegExp('^\\d+$') // only allow numbers
        const value = e.target.value.replace(' ', '')

        if (regex.test(value)) {
            setError(false)
        } else {
            maybeSetError()
        }

        setNumber(value)
    }

    function handleInputAdditionChange(e) {
        const regex = new RegExp('^[A-Z]+$') // only allow letters
        const value = e.target.value.replace(' ', '')

        if (regex.test(value)) {
            setError(false)
        } else {
            maybeSetError()
        }

        setAddition(value)
    }

    function handleButtonClick() {
        if (error || suggestedStreetName === '') {
            setError(true)
            return
        }

        const onboardingUrl = REDIRECT_URL + 'verbruik?area_code=' + areaCode + '&house_number=' + number + '&house_addition=' + addition
        window.open(onboardingUrl, '_blank')
    }

    return (
        <form className="energy-zero-address-checker frame">
            <div className="pt-0 pb-0 bit-xxs-12">
                <label htmlFor="inputAreaCode">
                    Postcode
                    <input
                        id="inputAreaCode"
                        autoComplete="postal-code"
                        type="text"
                        aria-label="postcode"
                        placeholder="1234AB"
                        required
                        value={areaCode}
                        onChange={handleInputAreaCodeChange}
                    />
                </label>
            </div>
            <div className="pt-0 pb-0 bit-xxs-12 bit-md-6">
                <label htmlFor="inputNumber">
                    Huisnummer
                    <input
                        id="inputNumber"
                        autoComplete="number"
                        type="text"
                        aria-label="huisnummer"
                        placeholder="123"
                        required
                        value={number}
                        onChange={handleInputNumberChange}
                    />
                </label>
            </div>
            <div className="pt-0 pb-0 bit-xxs-12 bit-md-6">
                <label htmlFor="inputAddition">
                    Toevoeging
                    <input
                        id="inputAddition"
                        autoComplete="chrome-off"
                        type="text"
                        aria-label="toevoeging"
                        placeholder="A"
                        onChange={handleInputAdditionChange}
                    />
                </label>
            </div>
            <div className="bit-xxs-12 pb-0">
                <button
                    type="button"
                    onClick={handleButtonClick}
                >
                    Nu berekenen
                </button>

                <div className="energy-zero-address-checker__message-wrapper">
                    {error && <p className="energy-zero-address-checker__error">
                        Vul minimaal een geldig postcode en huisnummer in.
                    </p>}

                    {suggestedStreetName && <p className="energy-zero-address-checker__streetname">
                        <i className="fas fa-check-circle"/>
                        <span className="pl-5">{suggestedStreetName}</span>
                    </p>}
                </div>

            </div>
        </form>
    )
}
