import Review from "../review";
import Button from "../button";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 0,
  breakpoints: {
    1024: {
      spaceBetween: 15,
      slidesPerView: 3,
    },
  },
};

export default function Reviews({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-sm-center">
          <div className="bit-xxs-12 bit-lg-10">
            {block.title && (
              <div
                className="cb--reviews__title"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="pb-20"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
          </div>
        </div>
        <div className="cb--reviews__slider pb-50">
          <Swiper
            modules={[Pagination]}
            spaceBetween={swiperSettings.spaceBetween}
            slidesPerView={swiperSettings.slidesPerView}
            breakpoints={swiperSettings.breakpoints}
            pagination={{ clickable: true }}
          >
            {block.reviews.length &&
              block.reviews.map((review) => {
                return (
                  <SwiperSlide key={review.title}>
                    <Review
                      title={review.title}
                      text={review.text}
                      rating={review.rating}
                      date={review.date}
                      name={review.name}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        {block.button && (
          <Button button={block.button} classes="button button--gray" />
        )}
      </div>
    </>
  );
}
