import Button from "../button";
import Modal from "../modal";
import { useRouter } from "next/router";

export default function CbOffer({ block }) {
  const router = useRouter();
  const requested_hash = router.query && router.query.hash;

  return (
    <>
      {requested_hash && requested_hash !== block.offer.current_hash && (
        <Modal
          title={block.offer.offer_expired_title ?? "Expired"}
          hasCloseButton={false}
        >
          <div>{block.offer.offer_expired_text ?? "Expired"}</div>
          {block.offer.offer_expired_button && (
            <Button
              button={block.offer.offer_expired_button}
              classes="button mt-30"
            />
          )}
        </Modal>
      )}
      <div className="container">
        {block.offer.current_hash && (
          <p style={{ display: "none" }}>
            Current hash: {block.offer.current_hash}
          </p>
        )}
        <div className="frame frame--justify-md-space-between">
          <div className="bit-xxs-12 hidden-desktop">
            {block.text_above_title && (
              <p className="lead text--italic text--gray pb-5">
                {block.text_above_title}
              </p>
            )}
            {block.title && (
              <h2
                className="text--gray text--weight-normal text--weight-demi"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
          </div>
          <div className="bit-xxs-12 bit-md-6 bit-lg-5 bit-order-md-2">
            <div className="cb--offer__box p-15 p-sm-30 pt-sm-50 pb-sm-50">
              <div className="cb--offer__box__section pt-20 pb-15">
                {block.offer.title && (
                  <p className="h5 pb-0">{block.offer.title}</p>
                )}
              </div>
              <div className="cb--offer__box__section pt-15 pb-15">
                <div className="frame m-0">
                  <div className="bit-xxs-3 p-0" />
                  <div className="bit-xxs-3 p-0">
                    <span className="text--italic text--weight-demi">
                      Levertarief
                    </span>
                  </div>
                  <div className="bit-xxs-3 p-0">
                    <span className="text--italic text--weight-demi">
                      Belastingen³
                    </span>
                  </div>
                  <div className="bit-xxs-3 p-0">
                    <span className="text--italic text--weight-demi">
                      Totaaltarief
                    </span>
                  </div>
                </div>
              </div>
              {block.offer.tables &&
                block.offer.tables.length &&
                block.offer.tables.map((table) => {
                  return (
                    <div className="cb--offer__box__section pt-20 pb-20">
                      <div className="frame m-0">
                        <div className="bit-xxs-12 p-0">
                          {table.title && (
                            <p className="pb-10 text--gray text--italic text--weight-bold">
                              {table.title}
                            </p>
                          )}
                        </div>
                        {table.rows &&
                          table.rows.length &&
                          table.rows.map((row) => {
                            return (
                              <>
                                <div className="bit-xxs-3 p-0">
                                  {row.title && (
                                    <p className="p-0">{row.title}</p>
                                  )}
                                </div>
                                <div className="bit-xxs-3 p-0">
                                  {row.levertarief && (
                                    <p className="p-0">
                                      €{" "}
                                      {row.levertarief
                                        .toString()
                                        .replace(".", ",")}
                                    </p>
                                  )}
                                </div>
                                <div className="bit-xxs-3 p-0">
                                  {row.belastingen && (
                                    <p className="p-0">
                                      €{" "}
                                      {row.belastingen
                                        .toString()
                                        .replace(".", ",")}
                                    </p>
                                  )}
                                </div>
                                <div className="bit-xxs-3 p-0">
                                  {row.totaaltarief && (
                                    <p className="p-0">
                                      €{" "}
                                      {row.totaaltarief
                                        .toString()
                                        .replace(".", ",")}
                                    </p>
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              {block.offer.text_after_tables && (
                <div
                  className="text--weight-bold text--gray pt-20"
                  dangerouslySetInnerHTML={{
                    __html: block.offer.text_after_tables,
                  }}
                />
              )}
              {block.offer.small_text_after_tables && (
                <div
                  className="text--italic"
                  dangerouslySetInnerHTML={{
                    __html: block.offer.small_text_after_tables,
                  }}
                />
              )}
            </div>
          </div>
          <div className="bit-xxs-12 bit-md-6 bit-order-md-1">
            <div className="visible-desktop pb-30">
              {block.text_above_title && (
                <p className="lead text--italic text--gray pb-5">
                  {block.text_above_title}
                </p>
              )}
              {block.title && (
                <p
                  className="h2 text--gray text--weight-normal text--weight-demi"
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />
              )}
            </div>
            {block.usp_lists &&
              block.usp_lists.length &&
              block.usp_lists.map((usp_list) => {
                return (
                  <div className="pb-40 pb-md-50">
                    {usp_list.title && (
                      <h3 className="h4 pb-10">{usp_list.title}</h3>
                    )}
                    {usp_list.usps &&
                      usp_list.usps.length &&
                      usp_list.usps.map((usp) => {
                        return (
                          usp.text && (
                            <div className="flex pb-10">
                              <i className="fas fa-plus-circle mr-10 mt-7" />
                              <span>{usp.text}</span>
                            </div>
                          )
                        );
                      })}
                  </div>
                );
              })}

            <div className="flex flex--wrap flex--align-center">
              {block.button && (
                <Button
                  button={block.button}
                  classes="button mb-30 mb-sm-0 mr-sm-30"
                />
              )}
              {block.link && (
                <Button button={block.link} classes="button button--link" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
