export default function cbSavingsExample({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-md-space-between frame--align-md-center">
          <div className="bit-xxs-12 bit-md-6">
            {block.title && (
              <div
                className="pb-20"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.content && (
              <div dangerouslySetInnerHTML={{ __html: block.content }} />
            )}
          </div>
          <div className="bit-xxs-12 bit-md-6 bit-lg-5">
            <div className="cb--savings_example__box p-30 p-md-50 text--weight-normal">
              {block.example_title && (
                <p className="h5 pb-10">{block.example_title}</p>
              )}
              <hr className="mt-10 mb-20" />
              {block.rows &&
                block.rows.length &&
                block.rows.map((row) => {
                  return (
                    <div className="flex flex--align-center flex--justify-space-between">
                      <p className="pt-5 pb-5 pb-md-10 text--lineheight-tight">
                        {row.title}
                      </p>
                      <p
                        className="pt-5 pb-5 pb-md-10 text--lineheight-tight text--align-right"
                        dangerouslySetInnerHTML={{ __html: row.text }}
                      />
                    </div>
                  );
                })}
              <hr className="mt-10 mb-20" />
              <div className="flex flex--align-center flex--justify-space-between">
                <p className="pt-5 pb-5 pb-md-10 text--lineheight-tight">
                  <strong>Besparing per maand</strong>
                </p>
                {block.total_savings_month && (
                  <p className="h6 pt-5 pb-5 text--align-right">
                    {block.total_savings_month}
                  </p>
                )}
              </div>
              <div className="flex flex--align-center flex--justify-space-between">
                <p className="pt-5 pb-5 pb-md-10 text--lineheight-tight">
                  <strong>Besparing per jaar</strong>
                </p>
                {block.total_savings_year && (
                  <p className="h6 pt-5 pb-5 text--align-right">
                    {block.total_savings_year}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
