import Button from "../button";
import ResponsiveImage from "../modules/responsive-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 0,
  breakpoints: {
    1024: {
      spaceBetween: 30,
      slidesPerView: 3,
    },
  },
};

export default function CbSteps_1({ block }) {
  return (
    <>
      <div className="container pt-30 pb-30">
        <div className="frame frame--justify-md-center pb-20">
          <div className="bit-xxs-12 bit-md-8">
            {block.title && (
              <div
                className="cb--steps_1__title text--align-center pb-10 pb-md-20"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="text--align-center"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
          </div>
        </div>
        <div className="cb--steps_1__slider">
          <Swiper
            modules={[Pagination]}
            spaceBetween={swiperSettings.spaceBetween}
            slidesPerView={swiperSettings.slidesPerView}
            breakpoints={swiperSettings.breakpoints}
            pagination={{ clickable: true }}
          >
            {block.steps.length &&
              block.steps.map((step) => {
                return (
                  <SwiperSlide key={step.label}>
                    <div className="cb--steps_1__step">
                      <span className="cb--steps_1__step__label">
                        {step.label}
                      </span>
                      {step.image && (
                        <figure className="cb--steps_1__step__image">
                          <ResponsiveImage image={step.image} />
                        </figure>
                      )}
                      {step.title && (
                        <p className="cb--steps_1__step__title">{step.title}</p>
                      )}
                      {step.text && (
                        <div
                          className="cb--steps_1__step__text"
                          dangerouslySetInnerHTML={{ __html: step.text }}
                        />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="flex flex--column flex--align-center pt-50">
          {block.button && (
            <Button button={block.button} classes="button button--gray mb-30" />
          )}
          {block.link && (
            <Button button={block.link} classes="button button--link" />
          )}
        </div>
      </div>
    </>
  );
}
