import EnergyZeroDynamicTariffs from "../energy-zero/dynamic-tariffs";

export default function CbEnergyZeroDynamicTariffs({block}) {
    return (
        <>
            <div className="container">
                <div className="frame frame--justify-md-center">
                    <div className="bit-xxs-12 bit-md-9">
                        {block.title && (
                            <div
                                className="cb--cta_1__title text--align-center pb-10"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                        {block.text && (
                            <div
                                className="text--align-center text--weight-normal pl-5 pr-5 pb-30"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}
                        <div>
                            <EnergyZeroDynamicTariffs />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
