import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";

export default function cbCta_2({ block }) {
  return (
    <>
      <div className="cb--cta_2__image-wrapper">
        <figure className="cb--cta_2__image">
          {block.image && (
            <ResponsiveImage
              image={block.image}
              classes="cb--cta_2__image__img"
              layout="fill"
            />
          )}
        </figure>
      </div>
      <div className="container">
        <div className="frame frame--justify-sm-center frame--justify-md-flex-end">
          <div className="bit-xxs-12 bit-sm-10 bit-md-8 bit-lg-7">
            <div className="cb--cta_2__box p-30 p-lg-50">
              {block.title && (
                <div
                  className="cb--cta_2__title"
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />
              )}
              {block.text && (
                <div
                  className="pb-10"
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              )}
              {block.button && (
                <Button button={block.button} classes="button button--gray" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
