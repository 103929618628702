import ResponsiveImage from "../modules/responsive-image";
import GravityForm from "../modules/gravity-form-2";
import Router from "next/router";
import { gtm } from "../../lib/gtm";

function afterSubmitSuccesGaEvent(form_title) {
  gtm.push({
    event: "form_submission",
    form_title: form_title,
  });
}

function afterSubmitSuccessRedirect(path) {
  Router.push(path);
}

export default function CbForm({ block }) {
  return (
    <>
      {block.image && (
        <figure className="cb--form__image">
          <ResponsiveImage image={block.image} />
        </figure>
      )}
      <div className="container">
        <div className="frame frame--justify-md-center">
          {block.title && block.text && (
            <div className="bit-xxs-12 bit-md-10 bit-lg-8 pt-0 pb-20">
              {block.title && (
                <div
                  className="cb--form__title pb-10"
                  dangerouslySetInnerHTML={{ __html: block.title }}
                />
              )}
              {block.text && (
                <div
                  className="text--weight-normal text-center"
                  dangerouslySetInnerHTML={{ __html: block.text }}
                />
              )}
            </div>
          )}
          <div
            className={
              block.form_has_two_cols
                ? "bit-xxs-12"
                : "bit-xxs-12 bit-md-6 bit-lg-8"
            }
          >
            {block.form && block.form.id && (
              <div
                className={block.form_has_two_cols ? "form-with-two-cols" : ""}
              >
                <GravityForm
                  backendUrl="/api/forms"
                  formID={block.form.id}
                  jumpToConfirmation={false}
                  onSubmitSuccess={() => {
                    // log gtm event
                    afterSubmitSuccesGaEvent(block.form.title);

                    // redirect if page is set
                    if (block.form_after_submit_success_page) {
                      afterSubmitSuccessRedirect(
                        block.form_after_submit_success_page
                      );
                      return false;
                    }

                    // show default confirmation notice
                    return true;
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
