import ResponsiveImage from "../modules/responsive-image";

export default function CbTimeline({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-md-center">
          <div className="bit-xxs-12 bit-md-10 bit-lg-9">
            {block.title && (
              <div
                className="text-center pb-10"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="text-center text--weight-normal"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
          </div>
          <div className="bit-xxs-12 bit-lg-10">
            <div className="cb--timeline__timeline">
              {block.items.length &&
                block.items.map((item) => {
                  return (
                    <div
                      className="cb--timeline__timeline__item"
                      key={item.title}
                    >
                      {item.title && (
                        <p className="h4 cb--timeline__timeline__item__title">
                          {item.title}
                        </p>
                      )}
                      {item.text && (
                        <div
                          className="text--weight-normal pb-10"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      )}
                      {item.image && (
                        <figure>
                          <ResponsiveImage image={item.image} />
                        </figure>
                      )}
                    </div>
                  );
                })}
              {block.title_after_timeline && block.text_after_timeline && (
                <div className="cb--timeline__timeline__item">
                  <p className="h4 cb--timeline__timeline__item__title">
                    {block.title_after_timeline}
                  </p>
                  <div
                    className="text--weight-normal pb-10"
                    dangerouslySetInnerHTML={{
                      __html: block.text_after_timeline,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
