export default function CbInPageNavigation({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame">
          <div className="bit-xxs-12 text-center">Buttons hier plaatsen</div>
        </div>
      </div>
    </>
  );
}
