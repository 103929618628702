import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import Breadcrumbs from "../breadcrumbs";
import EnergyZeroAddressChecker from "../energy-zero/address-checker";

export default function CbBanner_2({block}) {
    return (<>
        <figure className="cb--banner_2__image">
            {block.image && (<ResponsiveImage
                image={block.image}
                layout="fill"
                classes="cb--banner_2__image__img"
            />)}
        </figure>
        <div className="container pt-30 pt-sm-75 pb-sm-50">
            <div className="frame frame--justify-sm-center frame--align-md-center">
                <div className="bit-xxs-12 bit-sm-9 bit-md-6">
                    <div className="pl-10 pb-10 pb-md-0">
                        <div className="text--white pb-10">
                            <Breadcrumbs html="breadcrumbs here"/>
                        </div>
                        {block.title && (<div
                            className="cb--banner_2__title pr-lg-75"
                            dangerouslySetInnerHTML={{__html: block.title}}
                        />)}
                    </div>
                </div>
                <div className="bit-xxs-12 bit-sm-9 bit-md-6">
                    <div className="cb--banner_2__box p-30 p-sm-40 pl-sm-50 pr-sm-50">
                        {block.title_in_box && (<p className="cb--banner_2__box-title h3 pb-10">
                            {block.title_in_box}
                        </p>)}
                        {block.rating.url && (
                            <a className="cb--banner_2__rating flex pb-20" href={block.rating.url} target="_blank">
                                {block.rating.stars && (<div
                                    className="pr-10"
                                    dangerouslySetInnerHTML={{__html: block.rating.stars}}
                                />)}
                                {block.rating.percentage && block.rating.text && (<div>
                                    <strong>{block.rating.percentage}%</strong>{" "}
                                    {block.rating.text}
                                </div>)}
                            </a>)}
                        {block.show_energy_zero_widget
                            ? <EnergyZeroAddressChecker/>
                            : <>
                                <div className="pb-20 pl-md-20">
                                    {block.usps.length && block.usps.map((usp) => {
                                        return (<div className="flex" key={usp.text}>
                                            <i className="fas fa-plus-circle mt-5 mr-10"/>
                                            <p className="pb-10">{usp.text}</p>
                                        </div>);
                                    })}
                                </div>
                                {block.button && (<Button
                                    button={block.button}
                                    classes="cb--banner_2__button button"
                                />)}
                            </>}
                        {block.text_under_button && (
                            <p className="cb--banner_2__text-under-button text--topline pb-0 pt-20">
                                {block.text_under_button}
                            </p>)}
                    </div>
                </div>
            </div>
        </div>
    </>);
}
