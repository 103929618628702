import Button from "../button";

export default function CbBanner8({block}) {
    return (
        <>
            <div className="container pt-30 pt-sm-50 pt-md-75">
                <div
                    className="frame frame--justify-sm-center frame--justify-md-flex-start frame--justify-lg-space-between">
                    <div className="bit-xxs-12 bit-sm-9 bit-md-9 bit-lg-7">
                        {block.title && (
                            <div
                                className="cb--banner_8__title"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                        {block.text && (
                            <div
                                className="pb-10 pr-md-100 text--weight-normal"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}
                        <div className="pb-20 pb-sm-40 pl-20">
                            {block.usps.length &&
                                block.usps.map((usp) => {
                                    return (
                                        <p
                                            key={usp.text}
                                            className="flex flex--align-flex-start text--italic text--weight-demi text--lineheight-tight pb-10 pb-md-15"
                                        >
                                            <i className="far fa-check-circle mt-2 mt-md-0 mr-5 mr-md-10"/>
                                            <span>{usp.text}</span>
                                        </p>
                                    );
                                })}
                        </div>
                        <div className="flex">
                            {block.button_left && (
                                <Button
                                    button={block.button_left}
                                    classes="button button--green-light button--white-shadow mr-20 mr-md-30"
                                />
                            )}
                            {block.button_right && (
                                <Button
                                    button={block.button_right}
                                    classes="button button--gray button--white-shadow"
                                />
                            )}
                        </div>
                    </div>

                    {block.show_cta && block.cta_text && block.cta_link && (
                        <div className="bit-xxs-12 bit-sm-9 bit-md-9 bit-lg-4 !tw-pt-10">
                            <div
                                className="tw-bg-white/90 tw-shadow-lg tw-rounded-[30px] tw-pt-8 lg:tw-pt-8 tw-pb-10 tw-px-8 lg:tw-px-8">
                                {block.cta_text && (
                                    <p className="tw-font-bold tw-text-[24px] tw-text-[#5BA99B] tw-leading-tight">{block.cta_text}</p>
                                )}
                                {block.cta_link && (
                                    <Button
                                        button={block.cta_link}
                                        classes="button button--gray button--white-shadow !tw-w-full"
                                    />
                                )}
                            </div>
                        </div>
                    )}

                </div>

                <figure
                    className="tw-my-20 tw-max-w-[300px] tw-mx-auto lg:tw-max-w-[unset] lg:tw-absolute lg:tw-right-[1.5rem] xl:tw-right-[calc((100vw-125rem)/2)] lg:tw-top-1/2 lg:-tw-translate-y-1/2 lg:tw-w-[400px]">
                    <img src="images/banner_8_logo.png" className="tw-contain tw-w-full"/>
                </figure>
            </div>
        </>
    );
}
