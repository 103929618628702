import {useState} from "react";

export default function Accordion({title, content, color = null}) {
    let [isOpen, setIsOpen] = useState(false)

    function toggle() {
        isOpen = setIsOpen(!isOpen)
    }

    return (<div className={`accordion ${isOpen ? 'accordion--is-open' : ''} ${color ? 'accordion--' + color : ''}`}>
            <div className="accordion__header" onClick={toggle}>
                <p className="mr-20" dangerouslySetInnerHTML={{__html: title}}/>
                <i className="fas fa-angle-down"/>
            </div>
            <div className="accordion__content">
                <div className="pt-10 text--weight-normal" dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </div>)
}