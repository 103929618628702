import ResponsiveImage from "../modules/responsive-image";
import Breadcrumbs from "../breadcrumbs";
import Button from "../button";
import EnergyZeroAddressChecker from "../energy-zero/address-checker";
import Rating from "../rating";

export default function CbBanner6({block}) {
    return (
        <>
            <figure className="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-overflow-hidden">
                {block.background_image && (<ResponsiveImage
                    image={block.background_image}
                    layout="fill"
                    classes="tw-object-cover tw-w-full tw-h-full"
                />)}
            </figure>
            <div className="tw-relative">
                <div className="container tw-pt-32 lg:tw-pb-20">
                    <div className="tw-flex tw-flex-wrap tw-items-center lg:-tw-mx-10">
                        <div className="tw-w-full lg:tw-w-1/2 lg:tw-px-10 tw-pb-20 lg:tw-pb-0">
                            <div className="tw-text-white pb-10">
                                <Breadcrumbs html="breadcrumbs here"/>
                            </div>
                            {block.left.title && (<div
                                className="cb--banner_6__title"
                                dangerouslySetInnerHTML={{__html: block.left.title}}
                            />)}
                            {block.left.text && (<div
                                className="cb--banner_6__text tw-pb-4"
                                dangerouslySetInnerHTML={{__html: block.left.text}}
                            />)}
                            {block.left.button && (
                                <Button
                                    button={block.left.button}
                                    classes="button button--white-shadow !tw-text-green"
                                />
                            )}
                        </div>

                        <div className="tw-w-full lg:tw-w-1/2 lg:tw-px-10">
                            <div
                                className="tw-bg-white/90 tw-text-black tw-rounded-3xl tw-py-8 tw-px-12 lg:tw-py-10 lg:tw-px-20 tw-shadow-xl">
                                {block.right.title && (<p className="tw-text-green tw-font-bold tw-text-[34px]">
                                    {block.right.title}
                                </p>)}
                                {block.rating && <div className="tw-pb-10">
                                    <Rating rating={block.rating}/>
                                </div>}
                                <div>
                                    <EnergyZeroAddressChecker/>
                                </div>
                                <div>
                                    {block.right.text_under_form &&
                                        <p className="tw-font-bold tw-text-[14px] tw-text-green tw-uppercase">{block.right.text_under_form}</p>}
                                    {block.right.usps && block.right.usps.length && block.right.usps.map((usp) => {
                                        return (<div className="flex" key={usp.text}>
                                            <i className="fas fa-plus-circle mt-5 mr-10 !tw-text-green"/>
                                            <p className="pb-10">{usp.text}</p>
                                        </div>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
