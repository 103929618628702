import ResponsiveImage from "../modules/responsive-image";

export default function CbLinkCards({block}) {
    return (
        <>
            <div className="container tw-relative tw-z-20">
                <div className="tw-flex tw-flex-wrap md:-tw-mx-8 tw-gap-y-16">
                    {block.cards && block.cards.length && block.cards.map((card) => {
                        return <>
                            <div className="tw-w-full md:tw-w-1/2 lg:tw-w-1/4 md:tw-px-8" key={card.title}>
                                <a href={card.link.url}
                                   className="cb--link_cards__card tw-shadow-xl">
                                    <div className="tw-p-12">
                                        <figure className="cb--link_cards__card__image">
                                            {card.image && <ResponsiveImage image={card.image} />}
                                        </figure>
                                        {card.title &&
                                            <p className="cb--link_cards__card__title tw-pb-0" dangerouslySetInnerHTML={{__html: card.title}} />}
                                    </div>
                                    {card.link.title &&
                                        <p className="cb--link_cards__card__button">{card.link.title}</p>}
                                </a>
                            </div>
                        </>;
                    })}
                </div>
            </div>
        </>
    );
}
