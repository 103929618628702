export default function Quote({ text }) {
  return (
    <div className="quote">
      <p className="quote__shape" />
      {text && (
        <div
          className="quote__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
}
