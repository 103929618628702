import Button from "../button";

export default function CbCta_1({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-md-center">
          <div className="bit-xxs-12 bit-md-9">
            {block.title && (
              <div
                className="cb--cta_1__title text--align-center pb-10"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.content && (
              <div
                className="text--align-center text--weight-normal pl-5 pr-5 pb-30"
                dangerouslySetInnerHTML={{ __html: block.content }}
              />
            )}
            {block.usps && block.usps.length && (
                <div className="flex flex--column flex--align-center pb-40">
                  {block.usps.map((usp) => {
                    return (
                        <div className="flex" key={usp.text}>
                          <i className="fas fa-plus-circle mt-5 mr-10" />
                          {usp.text && <p className="pb-10 text--weight-normal">{usp.text}</p>}
                        </div>
                    );
                  })}
                </div>
            )}
            <div className="flex flex--wrap flex--justify-center flex--align-center">
              {block.button && (
                <Button
                  button={block.button}
                  classes="button mb-30 mb-sm-0 mr-sm-30"
                />
              )}
              {block.link && (
                <Button button={block.link} classes="button button--link" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
