import ResponsiveImage from "./modules/responsive-image";

export default function ImageWithLogo({ image, isGreen = false }) {
  return (
    image && (
      <div
        className={`image-with-logo ${isGreen ? "image-with-logo--green" : ""}`}
      >
        <figure className="image-with-logo__image">
          <ResponsiveImage image={image} />
        </figure>
      </div>
    )
  );
}
