import ResponsiveImage from "../modules/responsive-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const swiperSettings = {
  slidesPerView: 1,
  spaceBetween: 0,
  breakpoints: {
    1024: {
      spaceBetween: 30,
      slidesPerView: 3,
    },
  },
};

export default function CbTeam({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-md-center">
          <div className="bit-xxs-12 bit-md-10 bit-lg-8 text-center">
            {block.title && (
              <div
                className="pb-10"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="pb-20 text--weight-normal"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
          </div>
          <div className="bit-xxs-12">
            <div className="cb--team__slider">
              <Swiper
                modules={[Pagination]}
                spaceBetween={swiperSettings.spaceBetween}
                slidesPerView={swiperSettings.slidesPerView}
                breakpoints={swiperSettings.breakpoints}
                pagination={{ clickable: true }}
              >
                {block.people &&
                  block.people.length &&
                  block.people.map((person) => {
                    return (
                      <SwiperSlide key={person.name}>
                        <div className="cb--team__person">
                          {person.image && (
                            <figure className="cb--team__person__image mb-20">
                              <ResponsiveImage
                                image={person.image}
                                layout="fill"
                                classes="cb--team__person__image__img"
                              />
                            </figure>
                          )}
                          {person.name && <p className="h5">{person.name}</p>}
                          {person.subtitle && (
                            <p className="pb-0 text--black">
                              <strong>{person.subtitle}</strong>
                            </p>
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
