import Quote from "../quote";

export default function cbQuote({ block }) {
  return (
    <>
      <div className="container pt-sm-30 pb-sm-30 pt-sm-50 pb-sm-50">
        <div className="frame frame--justify-sm-center">
          <div className="bit-xxs-12 bit-sm-10 bit-md-8">
            {block.text && <Quote text={block.text} />}
          </div>
        </div>
      </div>
    </>
  );
}
