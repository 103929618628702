export default function CbTitle({block}) {
    return (
        <>
            <div className="container">
                {block.title && <div className="tw-text-center"
                    dangerouslySetInnerHTML={{__html: block.title}}></div>}
            </div>
        </>
    );
}
