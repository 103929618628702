export default function Modal(props) {
  return (
    <div className="modal">
      {props.hasCloseButton && <div className="modal__close" />}
      <div className="modal__modal">
        <p className="modal__title h3">{props.title}</p>
        <div className="modal__content">{props.children}</div>
      </div>
    </div>
  );
}
