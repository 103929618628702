export default function Rating({rating}) {
    return (
        rating.url &&
        <a className="tw-text-[inherit] tw-flex tw-no-underline tw-text-[14px] hover:tw-underline hover:tw-text-[inherit]" href={rating.url} target="_blank">
            {rating.stars && (<div
                className="tw-pr-2.5 [&>i]:!tw-text-green"
                dangerouslySetInnerHTML={{__html: rating.stars}}
            />)}
            {rating.percentage && rating.text && (<div>
                <strong>{rating.percentage}%</strong>{" "}
                {rating.text}
            </div>)}
        </a>
    )
}