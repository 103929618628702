import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import ImageWithLogo from "../image-with-logo";

export default function cbImageAndText({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-sm-center frame--justify-lg-space-between frame--align-md-center">
          <div className="bit-xxs-12 bit-sm-9 bit-md-6 bit-lg-5">
            {block.image && (
              <ImageWithLogo
                image={block.image}
                isGreen={block.settings.font_color === "green"}
              />
            )}
          </div>
          <div className="bit-xxs-12 bit-sm-9 bit-md-6">
            {block.title && (
              <div
                className="cb--image_and_text__title"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="pb-20"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
            {block.usps.length && (
              <div className="pb-20 pb-md-30 pl-md-40">
                {block.usps.map((usp) => {
                  return (
                    <div className="flex" key={usp.text}>
                      <i className="fas fa-plus-circle mt-5 mr-10" />
                      {usp.text && <p className="pb-10">{usp.text}</p>}
                    </div>
                  );
                })}
              </div>
            )}

            {block.button && <Button button={block.button} classes="button" />}
          </div>
        </div>
      </div>
    </>
  );
}
