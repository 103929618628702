import GoogleReview from "../google-review";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Modal from "../modal";

const swiperSettings = {
    slidesPerView: 1.2,
    spaceBetween: 0,
    breakpoints: {
        768: {
            slidesPerView: 2.2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
};

export default function CbGoogleReviews({block}) {
    return (
        <>
            <div className="container">
                <div className="frame frame--justify-md-center">
                    <div className="bit-xxs-12 bit-md-9">
                        {block.title && (
                            <div
                                className="cb--cta_1__title text--align-center pb-10"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                        {block.text && (
                            <div
                                className="text--align-center text--weight-normal"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}
                    </div>
                    <div className="bit-xxs-12 bit-md-11">
                        {
                            block.reviews && block.reviews.length &&
                            <div className="cb--google_reviews__slider">
                                <Swiper
                                    modules={[Pagination]}
                                    spaceBetween={swiperSettings.spaceBetween}
                                    slidesPerView={swiperSettings.slidesPerView}
                                    breakpoints={swiperSettings.breakpoints}
                                    pagination={{clickable: true}}
                                >
                                    {block.reviews.map(review => <SwiperSlide key={review.author_name}>
                                        <GoogleReview review={review}/>
                                    </SwiperSlide>)}
                                </Swiper>
                            </div>
                        }

                        {
                            block.google_maps_button &&
                            <div className="flex flex--justify-center pt-50">
                                <a href={block.google_maps_button.url} target="_blank" className="button button--gray">
                                    {block.google_maps_button.title}
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
        ;
}
