import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Fancybox from "../fancybox";

const swiperSettings = {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
        1024: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
    },
};

export default function CbVideosSlider({block}) {
    return (
        <>
            <div className="container">
                <div className="frame frame--justify-sm-center">
                    <div className="bit-xxs-12">
                        {block.title && (
                            <div
                                className="text-center"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}
                    </div>
                    <div className="bit-xxs-12 bit-sm-8 bit-md-10">
                        <div className="cb--videos_slider__slider">
                            <Swiper
                                modules={[Pagination]}
                                spaceBetween={swiperSettings.spaceBetween}
                                slidesPerView={swiperSettings.slidesPerView}
                                breakpoints={swiperSettings.breakpoints}
                                pagination={{clickable: true}}
                            >
                                {block.videos.length &&
                                    block.videos.map((video) => {
                                        return (
                                            <SwiperSlide key={video.youtube_id}>
                                                <div className="cb--videos_slider__video">
                                                    <figure className="cb--videos_slider__video__placeholder">
                                                        {video.image && (
                                                            <ResponsiveImage
                                                                image={video.image}
                                                                classes="cb--videos_slider__video__placeholder__img"
                                                                layout="fill"
                                                            />
                                                        )}
                                                    </figure>
                                                    {video.youtube_id && (
                                                        <Fancybox options={{infinite: false}}>
                                                            <button
                                                                className="cb--videos_slider__video__play-button"
                                                                data-fancybox="video"
                                                                data-src={`https://www.youtube.com/watch?v=${video.youtube_id}`}
                                                            >
                                                                <i className="fas fa-play"/>
                                                            </button>
                                                        </Fancybox>
                                                    )}
                                                </div>

                                                {video.title && (
                                                    <div className="tw-pt-10 tw-text-center">
                                                        <p className="h5">{video.title}</p>
                                                    </div>
                                                )}

                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
