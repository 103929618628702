import Button from "../button";
import ResponsiveImage from "../modules/responsive-image";
import TwoImagesAndLogo from "../two-images-and-logo";

export default function CbImpact({ block }) {
  return (
    <>
      <div className="container">
        <div className="frame frame--justify-sm-center frame--justify-lg-space-between">
          <div className="bit-xxs-12 bit-sm-6 bit-sm-6 bit-lg-5 bit-order-md-2 mb-sm-30 mb-md-0">
            {block.images && block.images.length && (
              <TwoImagesAndLogo images={block.images} />
            )}
          </div>
          <div className="bit-xxs-12 bit-sm-8 bit-md-6 bit-order-md-1">
            {block.title && (
              <div
                className="cb--impact__title pb-md-10"
                dangerouslySetInnerHTML={{ __html: block.title }}
              />
            )}
            {block.text && (
              <div
                className="pb-md-20"
                dangerouslySetInnerHTML={{ __html: block.text }}
              />
            )}
            {block.usps.length &&
              block.usps.map((usp) => {
                return (
                  <div className="frame ml-n5 mr-n-10 pb-10" key={usp.title}>
                    <div className="bit-xxs-3 bit-sm-2 p-10 pt-20 pr-sm-10 pr-md-30">
                      <ResponsiveImage image={usp.icon} />
                    </div>
                    <div className="bit-xxs-9 bit-sm-9 p-10">
                      {usp.title && (
                        <p
                          className="text--weight-demi text--gray pb-0"
                          dangerouslySetInnerHTML={{ __html: usp.title }}
                        />
                      )}
                      {usp.text && (
                        <div dangerouslySetInnerHTML={{ __html: usp.text }} />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="cb--impact__button-wrapper">
          {block.button && (
            <Button button={block.button} classes="button button--gray" />
          )}
        </div>
      </div>
    </>
  );
}
