import ResponsiveImage from "./modules/responsive-image";

export default function FeaturedImage({ image, edgeColor = "" }) {
  return (
    <figure
      className={`featured-image ${
        edgeColor === "green" ? "featured-image--edge-color-green" : ""
      }`}
    >
      <ResponsiveImage
        image={image}
        layout="fill"
        classes="featured-image__img"
      />
    </figure>
  );
}
